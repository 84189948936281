<template>
  <v-chip
    class="ma-1"
    :color="resolvedColor"
    small
    dark
  >
    <span class="font-weight-bold">{{ resolvedName }}</span>
  </v-chip>
</template>

<script>
import { mapState } from 'vuex'
export default {

  props: {
    boardId: {
      type: String,
    },
    color: {
      type: String,
    },
    name: {
      type: String,
    },
  },

  computed: {
    ...mapState('app', [
      'tasksSettings',
    ]),
    taskBoard () {
      return this.tasksSettings.boards.find(board => board.id === this.boardId)
    },
    resolvedColor () {
      return this.color || this.taskBoard.color
    },
    resolvedName () {
      return this.name || this.taskBoard.name
    },
  },
}
</script>

<style>

</style>