import { db } from '@/firebase-setup'
import { doc, collection, addDoc, Timestamp } from 'firebase/firestore';

import FirestoreModel from './FirestoreModel';

class Task extends FirestoreModel {
    static collectionName = 'tasks';
    static allowedFields = [
      'walkId',
      'schoolId',
      'imagePaths',
      'location',
      'description',
      'timestamp',
      'priority',
      'observationId', // optional
      'observation',
      'createdByUserId',
      'dueDate',
      'timeCompleted',
      'status',
      'notes',
      'boardId',
    ]

    constructor(id, data) {
      super(id, data);
    }
}

export default Task;
