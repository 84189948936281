<template>
  <v-dialog
    v-if="tasksSettings"
    v-model="dialog"
    persistent
    width="600"
    style="z-index: 10000"
  >
    <v-card>
      <v-card-title>
        {{ isEdit ? 'Edit Task' : 'Create Task' }}
        <v-spacer />
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="taskForm"
          v-model="isTaskFormValid"
        >
          <div class="text-subtitle-1 mb-1">
            Required Fields
          </div>
          <school-select
            v-model="editTask.schoolId"
            class="mb-1"
            clearable
            outlined
            dense
            :rules="[v => !!v || 'School is required']"
            :permission="'propose tasks'"
            label="School"
            hide-details="auto"
          />

          <task-board-select
            v-if="tasksSettings.requiredFields.taskBoard"
            v-model="editTask.boardId"
            class="mb-1"
            :rules="[v => !!v || 'Task board is required']"
          />

          <users-select
            v-if="tasksSettings.requiredFields.initialAssignedUsers"
            v-model="editTask.assignedToUserId"
            :disabled="!editTask.schoolId"
            :rules="[v => !!v || 'Assigned users are required']"
            dense
            label="Assigned users"
            multiple
            persistent-hint
            hide-details="auto"
            show-self
            :valid-user-ids="getEligibleTaskAssigneesForTask(editTask).map(user => user.value)"
            outlined
            class="mb-1"
          />

          <v-textarea
            v-model="editTask.description"
            class="mb-1"
            label="Description"
            hide-details="auto"
            outlined
            dense
            required
            auto-grow
            :rows="3"
            :rules="[v => !!v || 'Description is required']"
          />

          <v-select
            v-if="tasksSettings.requiredFields.priority"
            v-model="editTask.priority"
            class="mb-1"
            label="Priority"
            hide-details="auto"
            outlined
            dense
            required
            :items="priorityItems"
            :rules="[v => !!v || 'Priority is required']"
          >
            <template #selection="{ item }">
              <v-chip
                color="#ddd"
                small
              >
                <v-icon
                  :color="item.color"
                  class="mr-1"
                  small
                >
                  mdi-flag
                </v-icon>
                <span class="font-weight-bold">{{ item.text }}</span>
              </v-chip>
            </template>
            <template #item="{ item }">
              <v-list-item-content>
                <v-list-item-title>
                  <v-chip
                    color="#ddd"
                    small
                  >
                    <v-icon
                      :color="item.color"
                      class="mr-1"
                      small
                    >
                      mdi-flag
                    </v-icon>
                    <span class="font-weight-bold">{{ item.text }}</span>
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>

          <observation-location-select
            v-if="tasksSettings.requiredFields.location"
            ref="locationCombobx"
            v-model="editTask.location"
            :rules="[v => !!v || 'Location is required']"
            :disabled="!editTask.schoolId"
            :school-id="editTask.schoolId"
            name="taskLocation"
            header-text="Select an existing location or type a new one."
            class="mb-1"
            clearable
          />
          <v-menu
            v-if="tasksSettings.requiredFields.dueDate"
            ref="menu"
            v-model="isDueDateMenuOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="editTask.dueDate"
                label="Requested completion date"
                outlined
                class="mb-1"
                dense
                clearable
                hide-details="auto"
                :rules="[v => !!v || 'Requested completion date is required']"
                readonly
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="editTask.dueDate"
              no-title
              scrollable
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(editTask.dueDate)"
              >
                {{ $t('common.save') }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.cancel()"
              >
                {{ $t('common.cancel') }}
              </v-btn>
            </v-date-picker>
          </v-menu>

          <v-textarea
            v-if="tasksSettings.requiredFields.notes"
            v-model="editTask.notes"
            class="mb-1"
            label="Notes"
            hide-details="auto"
            outlined
            dense
            required
            auto-grow
            :rows="3"
            :rules="[v => !!v || 'Notes are required']"
          />

          <div class="text-subtitle-1 my-1">
            Optional Fields
          </div>
          <task-board-select
            v-if="!tasksSettings.requiredFields.taskBoard"
            v-model="editTask.boardId"
            class="mb-1"
            clearable
          />
          <users-select
            v-if="!tasksSettings.requiredFields.initialAssignedUsers"
            v-model="editTask.assignedToUserId"
            :disabled="!editTask.schoolId"
            dense
            label="Assigned users"
            multiple
            persistent-hint
            hide-details="auto"
            show-self
            :valid-user-ids="getEligibleTaskAssigneesForTask(editTask).map(user => user.value)"
            outlined
            class="mb-1"
          />

          <v-select
            v-if="!tasksSettings.requiredFields.priority"
            v-model="editTask.priority"
            class="mb-1"
            label="Priority"
            hide-details="auto"
            outlined
            dense
            clearable
            :items="priorityItems"
          >
            <template #selection="{ item }">
              <v-chip
                color="#ddd"
                small
              >
                <v-icon
                  :color="item.color"
                  class="mr-1"
                  small
                >
                  mdi-flag
                </v-icon>
                <span class="font-weight-bold">{{ item.text }}</span>
              </v-chip>
            </template>
            <template #item="{ item }">
              <v-list-item-content>
                <v-list-item-title>
                  <v-chip
                    color="#ddd"
                    small
                  >
                    <v-icon
                      :color="item.color"
                      class="mr-1"
                      small
                    >
                      mdi-flag
                    </v-icon>
                    <span class="font-weight-bold">{{ item.text }}</span>
                  </v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>

          <observation-location-select
            v-if="!tasksSettings.requiredFields.location"
            ref="locationCombobx"
            v-model="editTask.location"
            :disabled="!editTask.schoolId"
            :school-id="editTask.schoolId"
            :name="isEdit ? 'editLocation' : 'newLocation'"
            header-text="Select an existing location or type a new one."
            class="mb-1"
            clearable
          />
          <v-menu
            v-if="!tasksSettings.requiredFields.dueDate"
            ref="menu"
            v-model="isDueDateMenuOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="editTask.dueDate"
                label="Requested completion date"
                outlined
                dense
                clearable
                class="mb-1"
                hide-details="auto"
                readonly
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="editTask.dueDate"
              no-title
              scrollable
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(editTask.dueDate)"
              >
                {{ $t('common.save') }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.cancel()"
              >
                {{ $t('common.cancel') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-textarea
            v-if="!tasksSettings.requiredFields.notes"
            v-model="editTask.notes"
            class="mb-1"
            label="Notes"
            hide-details="auto"
            outlined
            dense
            required
            auto-grow
            :rows="3"
          />
          <compressed-image-input
            v-model="editTask.pictureFiles"
            multiple
          />
          <!-- <v-checkbox v-model="editTask.shouldEmailCreatorWhenComplete" label="Send me an email when Task is completed" hide-details></v-checkbox> -->
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-2">
        <v-spacer />
        <v-btn
          color="grey darken-1"
          text
          @click="close"
        >
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="isSaving"
          :loading="isSaving"
          @click="save"
        >
          {{ isEdit ? 'Save' : 'Create' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import uuid from 'uuid'
import ImageUploadService from '@/services/ImageUploadService'
import CompressedImageInput from '../common/CompressedImageInput.vue'
import Task from '../../models/Task'
import SchoolSelect from '../common/SchoolSelect.vue'
import UsersSelect from '../common/UsersSelect.vue'
import TaskBoardSelect from './TaskBoardSelect.vue'
import ObservationLocationSelect from '../walks/ObservationLocationSelect.vue'

/*
|---------------------------------------------------------------------
| TODO Compose Component
|---------------------------------------------------------------------
|
| Compose new tasks editor
|
*/
export default {
  name: 'CreateTaskDialog',
  components: {
    SchoolSelect,
    UsersSelect,
    CompressedImageInput,
    TaskBoardSelect,
    ObservationLocationSelect,
  },
  props: {
    // whether we want an input event or an actual save
    walkId: {
      type: String,
      required: false,
    },
    observation: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      dialog: false,
      title: '',
      description: '',
      editTask: {},

      isTaskFormValid: false,

      search: null,

      isDueDateMenuOpen: false,

      initialTask: null,

      isSaving: false,
    }
  },
  computed: {
    ...mapGetters('app', [
      'getEligibleTaskAssigneesForTask',
    ]),
    ...mapState('app', [
      'tasksSettings',
      'user',
      'users',
    ]),
    isEdit() {
      return this.editTask && this.editTask.id
    },
    priorityItems () {
      return [
        {
          text: 'Low',
          value: 'Low',
          color: '#007BFF',
        },
        {
          text: 'Medium',
          value: 'Medium',
          color: '#E0B000',
        },
        {
          text: 'High',
          value: 'High',
          color: '#FD7E14',
        },
        {
          text: 'Critical',
          value: 'Critical',
          color: '#DC3545',
        },
      ]
    },
  },
  methods: {
    ...mapActions('app', [
      'syncTasks',
      'showSuccess',
    ]),
    async save () {
      await this.$refs.taskForm.validate()
      if (this.isTaskFormValid) {
        try {
          this.isSaving = true
          if (this.editTask.pictureFiles) {
            const newPictures = await this.uploadPictures(this.editTask);
            this.editTask.imagePaths =
            [
              ...(this.editTask.imagePaths || []),
              ...newPictures,
            ]
          }
          delete this.editTask.pictureFiles;
          const task = this.initialTask || new Task(null, {
            status: 'Assigned',
            createdByUserId: this.user.id,
          })
          
          Object.keys(this.editTask).forEach((key) => {
            task[key] = this.editTask[key]
          })
          this.close()
          if (this.walkId) {
            this.$emit('input', task)
          } else {
            await task.save()
            this.showSuccess('Task proposed successfully')
            this.syncTasks()
          }
        } finally {
          this.isSaving = false
        }
      }
    },
    open (task) {
      if (this.$refs.taskForm) {
        this.$refs.taskForm.resetValidation()
      }
      this.initialTask = task
      if (task) {
        this.editTask = task.clone()
      } else {
        this.editTask = {}
      }
      if (this.walkId) {
        this.editTask.walkId = this.walkId
      }
      if (this.observation && !task) {
        if (this.observation.observationType === 'concern') {
          this.editTask.title = 'Address this Concern'
        }
        this.editTask.description = this.observation.comment
        this.editTask.location = this.observation.location
      }
      this.dialog = true
    },
    close () {
      this.$refs.taskForm.resetValidation()
      this.dialog = false
    },
    async uploadPictures() {
      if (!this.editTask.pictureFiles) {
        return [];
      }

      const imagePaths = [];

      this.editTask.pictureFiles.forEach((file) => {
        const storageRef = ImageUploadService.beginFileUpload(`tasks/${this.user.id}/${uuid()}`, file);
        imagePaths.push(storageRef.fullPath);
      });

      return imagePaths;
    },
  },
}
</script>
