import configs from '../../configs'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { appConfig } from '../../app-config'

const { time, theme, currencies  } = configs

const { globalTheme, menuTheme, toolbarTheme, isToolbarDetached, isContentBoxed, isRTL } = theme
const { currency, availableCurrencies } = currencies

// state initial values
const state = {
  messagingToken: null,
  numTimesRequestedPermission: 0,

  time,
  globalAppSettings: null,
  schooldogUpdateMajor: false,
  schooldogUpdateMinor: false,

  // currency
  currency,
  availableCurrencies,

  // themes and layout configurations
  globalTheme,
  menuTheme,
  toolbarTheme,
  isToolbarDetached,
  isContentBoxed,
  isRTL,
  showGaBranding: appConfig.showGaBranding ?? false,

  isAuthInitialized: false,
  user: null,
  firebaseUser: null,

  // App.vue main toast
  toast: {
    show: false,
    color: 'black',
    message: '',
    timeout: 3000,
  },

  students: [],

  db: null,
  auth: null,
  isAuthInitialized: false,

  users: [],
  usersById: {},

  organizationSettings: null,
  observationSettings: null,
  walkSettings: null,
  tasksSettings: null,

  haveWalksLoaded: false,
  walks: [],
  walksById: {},
  lastTimeWalksUpdated: null,

  haveTasksLoaded: false,
  tasks: [],
  tasksById: {},
  lastTimeTasksUpdated: null,

  isOffline: false,
  isManuallyOffline: false,
  isNativelyOffline: false,

  schoolInfos: [],

  cycles: [],
  cyclesById: {},
  haveCyclesLoaded: true,

  rubrics: [],
  rubricsLoaded: false,

  inactivityTimestamp: null,

  isUnexpectedResetDialogVisible: false,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
