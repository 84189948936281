<template>
  <div
    v-if="tasksSettings"
    class="d-flex"
    style="width: 100%; max-width: 100%;"
  >
    <div
      class="flex-grow-1"
      style="width: 100%; max-width: 100%; margin-bottom: 64px;"
    >
      <div class="d-flex flex-column flex-grow-1 px-4 pb-4 pt-2">
        <div class="text-h5 mb-1">
          Tasks Settings
        </div>
        <div class="text-subtitle-2 mb-1">
          Configure how tasks get created and sent to users.
        </div>
        <div class="mt-2">
          <v-card>
            <v-card-title>Task Boards</v-card-title>
            <v-card-subtitle>
              Task Boards organize tasks and allow tasks to be viewed and worked on by the right people.
            </v-card-subtitle>
            <v-card-text>
              <div class="d-flex align-center justify-center">
                <v-text-field
                  v-model="searchQuery"
                  append-icon="mdi-magnify"
                  solo
                  hide-details="auto"
                  dense
                  clearable
                  placeholder="Search"
                  class="ma-2"
                />
                <v-btn
                  color="primary"
                  @click="startAddingTaskBoard()"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Add task board
                </v-btn>
              </div>
              <v-data-table
                :headers="taskBoardsHeaders"
                :items="taskBoardsItems"
              >
                <template #item.name="{ item }">
                  <task-board-chip
                    :color="item.color"
                    :name="item.name"
                  />
                </template>
                <template #item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="startEditingTaskBoard(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>

        <div class="mt-2">
          <v-card>
            <v-card-title>
              Required Fields
              <v-spacer />
              <v-btn
                v-if="!isEditingRequiredFields"
                color="primary"
                icon
                @click="startEditingRequiredFields()"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-subtitle>
              Configure which fields are required when creating a task (school and description are always required).
            </v-card-subtitle>
            <v-card-text>
              <div
                v-for="item in requiredFieldItems"
                :key="item.value"
                class="mb-1"
              >
                <v-switch
                  v-if="isEditingRequiredFields"
                  v-model="editRequiredFields[item.value]"
                  hide-details="auto"
                  :label="item.text"
                />
                <div
                  v-else
                  class="d-flex align-center"
                >
                  <v-icon
                    v-if="tasksSettings.requiredFields[item.value]"
                    color="success"
                  >
                    mdi-check
                  </v-icon>
                  <v-icon
                    v-else
                    color="error"
                  >
                    mdi-close
                  </v-icon>
                  <span class="text-body-1 ml-1">{{ item.text }}</span>
                </div>
              </div>
            </v-card-text>
            <v-card-actions v-if="isEditingRequiredFields">
              <v-spacer />
              <v-btn
                color="grey darken-1"
                text
                @click="isEditingRequiredFields = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="saveRequiredFields()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="isEditTaskBoardDialogVisible"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          {{ isEditingTaskBoard ? 'Edit' : 'Add' }} task board
          <v-spacer />
          <v-btn
            icon
            color="grey darken-1"
            @click="isEditTaskBoardDialogVisible = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="taskBoardForm"
            v-model="isAddTaskFormValid"
          >
            <v-text-field
              v-model="newTaskBoard.name"
              label="Task board name"
              outlined
              required
              hide-details="auto"
              maxlength="30"
              dense
              class="mb-2"
              :rules="[rules.required]"
            />
            <v-select
              v-model="newTaskBoard.color"
              :disabled="!newTaskBoard.name"
              :items="colorOptions"
              :rules="[rules.required]"
              label="Color"
              hide-details="auto"
              dense
              class="mb-2"
              outlined
              required
            >
              <template #selection="{ item }">
                <task-board-chip
                  :color="item.value"
                  :name="item.text"
                />
              </template>
              <template #item="{ item }">
                <task-board-chip
                  :color="item.value"
                  :name="item.text"
                />
              </template>
            </v-select>
            <v-text-field
              v-model="newTaskBoard.description"
              label="Description (optional)"
              maxlength="200"
              hide-details="auto"
              hint="An optional description to help users know which task board to select."
              outlined
            />
            <div class="text-center mt-3">
              <v-btn
                v-if="isEditingTaskBoard"
                color="error"
                text
                @click="isConfirmDeleteDialogVisible = true"
              >
                Delete task board
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="!isEditTaskBoardLoading"
            color="grey darken-1"
            text
            @click="isEditTaskBoardDialogVisible = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!isAddTaskFormValid || isEditTaskBoardLoading"
            :loading="isEditTaskBoardLoading"
            @click="saveTaskBoard()"
          >
            {{ isEditingTaskBoard ? 'Save' : 'Add' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isConfirmDeleteDialogVisible"
      max-width="428"
      persistent
    >
      <v-card>
        <v-card-title>
          Confirm delete
          <v-spacer />
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete this task board? This action cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="isConfirmDeleteDialogVisible = false"
          >
            No, Cancel
          </v-btn>
          <v-btn
            :disabled="isEditTaskBoardLoading"
            :loading="isEditTaskBoardLoading"
            color="error"
            @click="deleteTaskBoard()"
          >
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TaskBoardChip from '../../components/tasks/TaskBoardChip.vue';
const colors = [
  '#b24531', // dark red
  '#4444FF', // blue
  '#008000', // green
  '#800080', // purple
  '#EE9400', // orange
  '#008080', // teal
  '#4c4c00', // olive
  '#8B4513', // brown
  '#000080',  // navy
  '#888888', // grey
];

export default {
  name: 'TasksSettingsPage',
  components: { TaskBoardChip },
  data () {
    return {
      isAddTaskFormValid: false,
      isEditTaskBoardDialogVisible: false,
      newTaskBoard: {
        name: '',
        color: '',
        description: '',
      },
      searchQuery: '',
      rules: {
        required: value => Boolean(value) || 'Required.',
      },
      isEditTaskBoardLoading: false,
      isEditingTaskBoard: false,
      isConfirmDeleteDialogVisible: false,
      isEditingRequiredFields: false,
      editRequiredFields: null,
    };
  },
  computed: {
    ...mapState('app', [
      'tasksSettings',
    ]),
    taskBoardsHeaders () {
      return [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { value: 'actions', sortable: false },
      ];
    },
    taskBoardsItems () {
      return this.tasksSettings.boards.map((board) => ({
        ...board,
      }));
    },
    colorOptions () {
      return colors.map((color) => ({
        text: this.newTaskBoard.name,
        value: color,
      }));
    },
    requiredFieldItems () {
      return [        
        { text: 'Assigned users', value: 'initialAssignedUsers' },
        { text: 'Location', value: 'location' },
        { text: 'Notes', value: 'notes' },
        { text: 'Priority', value: 'priority' },
        { text: 'Requested completion date', value: 'dueDate' },
        { text: 'Task board', value: 'taskBoard' },
      ];
    },
  },
  methods: {
    ...mapActions('app', [
      'showSuccess',
    ]),
    startAddingTaskBoard () {
      this.isEditingTaskBoard = false;
      this.newTaskBoard = {
        name: '',
        color: '',
        description: '',
      };
      if (this.$refs.taskBoardForm) {
        this.$refs.taskBoardForm.resetValidation();
      }
      this.isEditTaskBoardDialogVisible = true;
    },
    async addTaskBoard () {
      this.tasksSettings.boards.push({
        ...this.newTaskBoard,
        id: this.generateFirestoreId(),
      });
      this.isEditTaskBoardLoading = true;
      try {
        await this.tasksSettings.save();
        this.isEditTaskBoardDialogVisible = false;
        this.showSuccess(`Task board "${this.newTaskBoard.name}" added.`);
      } finally {
        this.isEditTaskBoardLoading = false;
      }
    },
    generateFirestoreId() {
      return Math.random().toString(36).substring(2, 22);
    },
    startEditingTaskBoard (taskBoard) {
      this.newTaskBoard = JSON.parse(JSON.stringify(taskBoard));
      this.isEditingTaskBoard = true;
      this.isEditTaskBoardDialogVisible = true;
    },
    async saveTaskBoard () {
      if (this.isEditingTaskBoard) {
        const taskBoard = this.tasksSettings.boards.find(board => board.id === this.newTaskBoard.id);
        if (!taskBoard) {
          return;
        }
        Object.assign(taskBoard, this.newTaskBoard);
        try {
          this.isEditTaskBoardLoading = true;
          await this.tasksSettings.save();
          this.isEditTaskBoardDialogVisible = false;
        } finally {
          this.isEditTaskBoardLoading = false;
          this.showSuccess(`Task board "${this.newTaskBoard.name}" updated.`);
        } 
      } else {
        this.addTaskBoard();
      }
    },
    async deleteTaskBoard () {
      const taskBoard = this.tasksSettings.boards.find(board => board.id === this.newTaskBoard.id);
      if (!taskBoard || !this.isEditingTaskBoard || !this.isConfirmDeleteDialogVisible) {
        return;
      }
      const index = this.tasksSettings.boards.indexOf(taskBoard);
      this.tasksSettings.boards.splice(index, 1);
      try {
        this.isEditTaskBoardLoading = true;
        await this.tasksSettings.save();
        this.isConfirmDeleteDialogVisible = false;
        this.isEditTaskBoardDialogVisible = false;
        this.showSuccess(`Task board "${this.newTaskBoard.name}" deleted.`);
      } finally {
        this.isEditTaskBoardLoading = false;
      }
    },
    async saveRequiredFields () {
      try {
        this.isEditTaskBoardLoading = true;
        this.tasksSettings.requiredFields = this.editRequiredFields;
        await this.tasksSettings.save();
        this.isEditingRequiredFields = false;
        this.editRequiredFields = null;
        this.showSuccess('Required fields updated.');
      } finally {
        this.isEditTaskBoardLoading = false;
      }
    },
    startEditingRequiredFields () {
      this.editRequiredFields = JSON.parse(JSON.stringify(this.tasksSettings.requiredFields));
      this.isEditingRequiredFields = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
