import FirestoreModel from './FirestoreModel';

class TasksSettings extends FirestoreModel {
    static collectionName = 'settings';
    static allowedFields = [
      'boards',
      'requiredFields',
    ]

    constructor(id, data) {
      super(id, data);
    }
}

export default TasksSettings;
