<template>
  <v-select
    v-bind="$attrs"
    :value="value"
    :items="items"
    :label="label"
    hide-details="auto"
    dense
    outlined
    required
    @input="$emit('input', $event)"
  >
    <template #selection="{ item }">
      <task-board-chip
        v-if="item.value"
        :board-id="item.value"
      />
      <div v-else>
        {{ item.text }}
      </div>
    </template>
    <template #item="{ item }">
      <task-board-chip
        v-if="item.value"
        :board-id="item.value"
      />
      <div v-else>
        {{ item.text }}
      </div>
    </template>
  </v-select>
</template>

<script>
import { mapState } from 'vuex'
import TaskBoardChip from './TaskBoardChip.vue'
export default {

  components: {
    TaskBoardChip,
  },

  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: 'Task board',
    },
  },

  computed: {
    ...mapState('app', [
      'tasksSettings',
    ]),
    taskBoard () {
      return this.tasksSettings.board.find(board => board.id === this.boardId)
    },
    items () {
      return [
        ...(this.tasksSettings.requiredFields.taskBoard ? [] : [
          { text: 'None', value: null },
        ]),
        ...this.tasksSettings.boards.map(board => ({
          text: board.name,
          value: board.id,
        })).sort((a, b) => {
          if ('Other' === a.text) {
            return 1
          }
          if ('Other' === b.text) {
            return -1
          }
          return a.text.localeCompare(b.text)
        }),
      ]
    },
  },
}
</script>