<template>
  <div
    v-if="walk && user && !isLoading"
    style="display: flex; width: 100%;"
    :style="!$vuetify.breakpoint.mdAndUp ? 'padding-bottom: 84px' : ''"
  >
    <div style="flex: 1;">
      <v-toolbar
        color="#ffffff"
        style="left: 0; border-bottom: 1px solid #1b7476;"
        width="100%"
      >
        <walk-progress-bar
          :value="currentTab"
          :walk="walk"
          @input="$event === 1 ? startContinuing() : (currentTab = 0)"
        />
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          class="ml-2"
          color="#C58C99"
          icon
          @click="isSchoolResourcesDialogVisible = true"
        >
          <v-icon>mdi-help-box-multiple-outline</v-icon>
        </v-btn>
        <v-btn
          color="grey"
          icon
          @click="isSettingsDialogVisible = true"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
        <v-app-bar-nav-icon
          v-if="$vuetify.breakpoint.mdAndUp"
          @click.stop="isObservationsDrawerVisible = !isObservationsDrawerVisible"
        />
      </v-toolbar>
      <div
        class="px-3 py-2"
        :style="$vuetify.breakpoint.mdAndUp ? 'overflow-y: auto; height: calc(100vh - 64px - 64px)' : ''"
      >
        <div v-if="-1 === currentTab">
          <walk-header :walk="walk" />
          <walk-history-card
            v-if="walk.submissionHistory && walk.submissionHistory.length"
            :walk="walk"
            class="mt-1 mb-2"
          />

          <div class="row justify-center">
            <v-btn
              color="primary"
              large
              outlined
              @click="currentTab = 1"
            >
              Continue
            </v-btn>
          </div>
        </div>
        <div v-if="0 === currentTab">
          <div class="row justify-center pa-2 pt-0">
            <div
              v-for="observationCategory in observationCategories"
              :key="observationCategory.id"
              class="col-6"
              style="padding: 8px"
            >
              <v-card
                class="ma-0 pa-0"
                style="height: 100%; width: 100%; display: flex; flex-direction: column;"
                @click="openLogEntryDialogForCategory(observationCategory.id)"
              >
                <div class="text-subtitle-2 text-center pa-1">
                  {{ observationCategory.label }}
                </div>
                <v-spacer />
                <div
                  class="row justify-center mb-1"
                  style="align-items: end;"
                >
                  <v-img
                    :src="require(`@/assets/images/${getImagePathForCategory(observationCategory)}.png`)"
                    color="transparent"
                    contain
                    style="border: 2px solid gray; border-radius: 1000px; max-height: 80px !important; max-width: 80px !important;"
                  />
                </div>
              </v-card>
            </div>
            <div
              class="col-6"
              style="padding: 8px"
            >
              <v-card
                class="ma-0 pa-0"
                style="height: 100%; width: 100%; display: flex; flex-direction: column;"
                @click="openLogEntryDialogForCategory(null)"
              >
                <div class="text-subtitle-2 text-center pa-1">
                  Master List
                </div>
                <div
                  class="row justify-center mb-1"
                  style="align-items: end;"
                >
                  <v-img
                    :src="require(`@/assets/images/a_to_z.png`)"
                    color="transparent"
                    contain
                    style="border: 2px solid gray; border-radius: 1000px; max-height: 80px !important; max-width: 80px !important;"
                  />
                </div>
                <v-spacer />
                <div
                  class="row justify-center mb-1"
                  style="align-items: end;"
                />
              </v-card>
            </div>
          </div>
          <div>
            <div class="row justify-center">
              <v-btn
                color="primary"
                large
                text
                @click="startContinuing()"
              >
                Complete SchoolDog Walk <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>

        <div v-if="1 === currentTab">
          <v-card class="mb-2">
            <v-card-text>
              <div class="text-subtitle-1">
                Have any additional data to enter?
              </div>
              <div class="d-flex">
                <div
                  v-if="!walkObservations.length"
                  class="mr-2"
                >
                  <v-icon
                    color="warning"
                    large
                  >
                    mdi-alert
                  </v-icon>
                </div>
                <div>
                  You are about to complete a SchoolDog Walk containing {{ numberOfConcerns }} concern{{ numberOfConcerns === 1 ? '' : 's' }}, {{ numberOfPraises }} praise{{ numberOfPraises === 1 ? '' : 's' }}, and {{ numberOfOther }} other entries. <span v-if="!walkObservations.length">It is recommended to log at least one entry before completing.</span><span v-else>If you have more entries to log, please do so before completing.</span> <a
                    href="javascript:void(0)"
                    @click="currentTab = 0"
                  >Go back to log more entries</a>.
                  <div>
                    <div
                      v-if="!walkObservations.length"
                      class="mt-2"
                    >
                      If you no longer want to save the data in this SchoolDog Walk, you can <a
                        v-if="!isOffline"
                        href="javascript:void(0)"
                        @click="isDeleteWalkDialogVisible = true"
                      >permanently delete it</a><span v-else>permanently delete it once you back online</span>.
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
          <div
            v-if="walk.rubrics && walk.rubrics.length"
            class="text-h5 text--secondary"
          >
            Rubrics <span style="font-size: 14px">(<a
              href="javascript:void(0)"
              @click="scrollToContext()"
            >Skip</a>)</span>
          </div>
          <answer-rubric
            v-for="(rubric, index) in (walk.rubrics || [])"
            :key="rubric.id"
            :rubric="rubric"
            :all-observations="walkObservations"
            @update:rubric="updateRubric($event, index)"
            @deleteObservation="deleteObservation($event)"
            @editObservation="editObservation($event)"
          />
          <div
            ref="context"
            class="text-h5 text--secondary"
          >
            Additional context <span style="font-size: 14px">(<a
              href="javascript:void(0)"
              @click="scrollToSubmit()"
            >Skip</a>)</span>
          </div>
          <v-card
            class="mt-1 mb-2"
            style="max-width: 100%"
          >
            <v-card-text>
              <div class="text-subtitle-1">
                How was the weather?
              </div>
              <div
                :class="{
                  'row flex-nowrap px-3': $vuetify.breakpoint.mdAndUp,
                }"
                class="mt-1"
              >
                <v-select
                  v-model="walk.context.weatherPrecipitation"
                  :items="['Light Rain', 'Heavy Rain', 'Cloudy', 'Sunny', 'Ice/Snow', 'Other']"
                  dense
                  hide-details
                  label="Precipitation"
                  outlined
                  :class="{
                    'mr-1': $vuetify.breakpoint.mdAndUp,
                  }"
                  :menu-props="{ offsetY: true }"
                  @input="
                    saveWalk('precip')
                  "
                />
                <v-select
                  v-model="walk.context.weatherTemperature"
                  :items="['Below 33 Degrees Fahrenheit', '33-39 Degrees Fahrenheit', '40-49 Degrees Fahrenheit', '50-59 Degrees Fahrenheit', '60-69 Degrees Fahrenheit', '70-79 Degrees Fahrenheit', '80+ Degrees Fahrenheit']"
                  dense
                  hide-details
                  label="Temperature"
                  outlined
                  :class="{
                    'ml-1': $vuetify.breakpoint.mdAndUp,
                    'mt-1': !$vuetify.breakpoint.mdAndUp,
                  }"
                  :menu-props="{ offsetY: true }"
                  @input="
                    saveWalk('temp')
                  "
                />
              </div>
              <v-text-field
                v-model="walk.context.weatherComment"
                class="mt-1"
                hide-details
                label="Comment"
                outlined
                @blur="saveWalk('wc')"
              />
            </v-card-text>
          </v-card>
          <v-card class="mt-1 mb-2">
            <v-card-text>
              <div class="text-subtitle-1">
                Any final notes?
              </div>
              <v-textarea
                v-model="walk.context.additionalNotes"
                :rows="3"
                auto-grow
                class="mt-1"
                hide-details
                label="Additional notes"
                outlined
                dense
                @blur="saveWalk('add')"
              />
            </v-card-text>
          </v-card>
          <div
            v-if="isOffline"
            class="text-center error--text text--darken-2 mb-2"
          >
            You are currently offline, meaning you cannot Review and Complete. Your data for this SchoolDog Walk will be saved to your local device until you reconnect to the internet. Please try to complete after connecting to the internet.
          </div>
          <div
            v-if="walk.leaderUserId !== user.id"
            class="text-center error--text text--darken-2 mb-2"
          >
            Only the Leader for a SchoolDog Walk can Review and Complete.<br>The Leader for this SchoolDog Walk is <user-link :user-id="walk.leaderUserId" />.
          </div>
          <div class="row justify-center">
            <v-btn
              ref="submitBtn"
              :disabled="isSubmitting || isOffline || walk.leaderUserId !== user.id"
              color="primary"
              x-large
              @click="submitForReviewClicked()"
            >
              Review and Complete
            </v-btn>
          </div>
          <div class="row justify-center">
            <v-btn
              color="grey darken-1"
              large
              text
              @click="currentTab = 0"
            >
              Back
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mdAndUp"
      v-model="isObservationsDrawerVisible"
      class="elevation-1"
      floating
      permanent
      right
      width="360px"
      :style="!(isObservationsDrawerVisible && $vuetify.breakpoint.mdAndUp) ? 'width: 0' : ''"
    >
      <div>
        <div>
          <div style="display: flex; justify-content: center; align-items: center;">
            <div style="flex: 1">
              <v-card-title>
                Entries
              </v-card-title>
              <v-card-subtitle>
                {{ numberOfConcerns }} concern{{ numberOfConcerns === 1 ? '' : 's' }}, {{ numberOfPraises }} praise{{ numberOfPraises === 1 ? '' : 's' }}, {{ numberOfOther }} other
              </v-card-subtitle>
            </div>
            <div class="pa-1">
              <v-btn
                color="primary"
                outlined
                @click="openLogEntryDialogForCategory(null)"
              >
                Add
              </v-btn>
            </div>
          </div>
        </div>
        <v-divider color="primary" />
        <div
          v-if="walkObservations.length"
          class="px-2 pt-2"
          style="overflow-y: scroll; height: calc(100vh - 64px - 84px)"
        >
          <entry-card
            v-for="(observation, index) in walkObservations"
            :key="`${index}-observation`"
            dont-handle-state
            not-created
            :observation="observation"
            :observation-number="index + 1"
            :readonly="!(isUserLeader || observation.reportedByUserId === user.id)"
            :settings="observationSettings"
            class="mb-2"
            @delete="deleteObservation(observation)"
            @edit="editObservation(observation.id)"
          />
        </div>
        <div
          v-else
          class="text-subtitle-1 grey--text text--darken-1"
          style="overflow-y: scroll; height: calc(100vh - 64px - 84px); display: flex; justify-content: center; align-items: center"
        >
          No Entries Yet
        </div>
      </div>
    </v-navigation-drawer>

    <v-fab-transition v-if="!$vuetify.breakpoint.mdAndUp && walkObservations.length">
      <v-btn
        fixed
        color="grey darken-1"
        dark
        elevation="12"
        bottom
        left
        rounded
        x-large
        style="left: 100px; z-index: 4;"
        @click="isObservationsDialogVisible = true"
      >
        {{ walkObservations.length }} Entr{{
          walkObservations.length === 1 ? "y" : "ies"
        }}
      </v-btn>
    </v-fab-transition>

    <v-fab-transition v-if="!$vuetify.breakpoint.mdAndUp">
      <v-btn
        bottom
        color="#C58C99"
        elevation="12"
        fab
        fixed
        left
        @click="isSchoolResourcesDialogVisible = true"
      >
        <v-icon color="white">
          mdi-help-box-multiple-outline
        </v-icon>
      </v-btn>
    </v-fab-transition>

    <v-fab-transition v-if="!$vuetify.breakpoint.mdAndUp">
      <v-btn
        bottom
        color="primary"
        elevation="12"
        fab
        fixed
        right
        @click="openLogEntryDialogForCategory(null)"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <!-- DIALOGS -->
    <log-entry-dialog
      v-model="isLogEntryDialogVisible"
      :initial-observation-category-id="newObservationCategoryId"
      :school-id="walk.schoolId"
      :walk-id="walk.id"
      @save="saveNewObservation"
    />

    <log-entry-dialog
      v-model="isEditObservationDialogVisible"
      :initial-observation="editingObservation"
      :school-id="walk.schoolId"
      :walk-id="walk.id"
      is-editing
      @save="saveEditObservationChanges($event)"
    />

    <v-dialog
      v-model="isSettingsDialogVisible"
      max-width="600px"
    >
      <v-card>
        <div style="display: flex; justify-content: space-between; align-items: center">
          <div>
            <v-card-title>
              SchoolDog Walk Settings
            </v-card-title>
          </div>
          <v-btn
            class="mx-1"
            icon
            @click="isSettingsDialogVisible = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-card class="mb-2">
            <v-card-text>
              <div
                class="text-subtitle-1 mb-2 d-flex justify-space-between align-center"
                style="width: 100%"
              >
                <div>Access info</div>
                <v-btn
                  v-if="user && (walk.leaderUserId === user.id)"
                  text
                  color="primary"
                  @click="isManageWalkAccessDialogVisible = true"
                >
                  Manage access
                </v-btn>
              </div>
              <div>
                This SchoolDog Walk is being led by <user-link :user-id="walk.leaderUserId" />.
              </div>
              <div
                v-if="walk.collaborators && walk.collaborators.length"
                class="mt-2"
              >
                <div>
                  Additionally, there {{ walk.collaborators.length === 1 ? 'is' : 'are' }} {{ walk.collaborators.length }} collaborator{{ walk.collaborators.length === 1 ? '' : 's' }}:
                </div>
                <ul>
                  <li
                    v-for="collaborator in walk.collaborators"
                    :key="collaborator"
                  >
                    <user-link :user-id="collaborator" />
                  </li>
                </ul>
              </div>
              <manage-walk-access-dialog
                v-model="isManageWalkAccessDialogVisible"
                :walk="walk"
              />
            </v-card-text>
          </v-card>
          <v-card class="mb-2 mt-1">
            <v-card-text>
              <div
                class="text-subtitle-1 mb-2 d-flex justify-space-between align-center"
                style="width: 100%"
              >
                <div>School info</div>
                <v-btn
                  text
                  color="#C58C99"
                  @click="isSchoolResourcesDialogVisible = true"
                >
                  View resources
                </v-btn>
              </div>
              <div class="text-body-2 mb-1">
                <div class="font-weight-thin text-caption">
                  School Name
                </div>
                <div class="font-weight-medium">
                  {{ school.name }}
                </div>
              </div>
            </v-card-text>
          </v-card>
          <div
            v-if="!isOffline"
            class="d-flex justify-center"
          >
            <v-btn
              color="error"
              text
              @click="isDeleteWalkDialogVisible = true"
            >
              Permanently delete SchoolDog Walk
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="isSettingsDialogVisible = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="isObservationsDialogVisible"
      v-model="isObservationsDialogVisible"
    >
      <v-card>
        <div>
          <div style="display: flex; justify-content: space-between; align-items: center">
            <div>
              <v-card-title>
                Entries
              </v-card-title>
              <v-card-subtitle>
                {{ numberOfConcerns }} concern{{ numberOfConcerns === 1 ? '' : 's' }}, {{ numberOfPraises }} praise{{ numberOfPraises === 1 ? '' : 's' }}, {{ numberOfOther }} other
              </v-card-subtitle>
            </div>
            <v-btn
              class="mx-1"
              icon
              @click="isObservationsDialogVisible = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <v-divider />
          <div
            v-if="walkObservations.length"
            class="px-2 py-2"
          >
            <entry-card
              v-for="(observation, index) in walkObservations"
              :key="`${index}-observation`"
              dont-handle-state
              not-created
              :observation="observation"
              :observation-number="index + 1"
              :readonly="!(isUserLeader || observation.reportedByUserId === user.id)"
              :settings="observationSettings"
              class="mb-2"
              @delete="deleteObservation(observation)"
              @edit="editObservation(observation.id)"
            />
          </div>
          <div
            v-else
            class="text-subtitle-1 grey--text text--darken-1 pa-2"
          >
            No Entries Yet
          </div>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="isObservationsDialogVisible = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isSentForReviewDialogVisible"
      max-width="528px"
    >
      <v-card v-if="isSentForReviewDialogVisible">
        <v-card-title>
          Revisions Requested
        </v-card-title>
        <v-card-text>
          <div>
            This SchoolDog Walk was reviewed by <span v-if="reviewedBy">{{ reviewedBy.firstName }} {{ reviewedBy.lastName }}</span><span v-else>Unknown</span>, and revisions were requested for your consideration. Please review the comments below, determine whether to make revisions to the SchoolDog Walk, and then complete and finalize the report.
          </div>
          <div class="mt-2">
            <b>Review Comments</b>
            <div class="text-body-1">
              "{{ walk.submissionHistory[walk.submissionHistory.length - 1].comments }}"
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="isSentForReviewDialogVisible = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isReviewingSubmissionDialogVisible"
      max-width="528px"
    >
      <v-card>
        <v-progress-linear
          v-if="isSubmitting"
          indeterminate
          color="primary"
        />
        <v-form
          ref="submissionReviewForm"
          v-model="isSubmissionReviewFormValid"
        >
          <v-card-title>
            Review and Complete
          </v-card-title>
          <v-card-text>
            <div class="d-flex">
              <div>
                You are about to complete a SchoolDog Walk containing {{ numberOfConcerns }} concern{{ numberOfConcerns === 1 ? '' : 's' }}, {{ numberOfPraises }} praise{{ numberOfPraises === 1 ? '' : 's' }}, and {{ numberOfOther }} other entries. <span v-if="!walkObservations.length">It is recommended to log at least one entry before completing.</span><span v-else>If you have more entries to log, please do so before completing.</span>
              </div>
            </div>
            <div
              v-if="!walkObservations.length"
              class="mt-2"
            >
              If you no longer want to save the data in this SchoolDog Walk, you can <a
                href="javascript:void(0)"
                @click="isDeleteWalkDialogVisible = true"
              >permanently delete it</a>.
            </div>
            <v-checkbox
              v-if="!walk.hasSentNeighborhoodWatch && user.can('send neighborhood watch')"
              v-model="walk.shouldSendNeighborhoodWatch"
              hide-details
              @change="saveWalk()"
            >
              <template #label>
                <div>
                  Send <glossary-term text="&quot;Neighborhood Watch&quot;" /> email after completion
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-if="!walkObservations.length"
              v-model="acknowledgedZeroEntries"
              :rules="[v => acknowledgedZeroEntries || 'Required']"
              label="I want to complete this SchoolDog Walk despite having zero entries"
              hide-details
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="grey darken-1"
              text
              @click="isReviewingSubmissionDialogVisible = false"
            >
              Cancel
            </v-btn>
            <v-btn
              :disabled="isSubmitting"
              :loading="isSubmitting"
              color="primary"
              @click="doSubmissionAction()"
            >
              Complete SchoolDog Walk
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="school"
      v-model="isSchoolResourcesDialogVisible"
      max-width="640px"
    >
      <v-card>
        <div style="display: flex; justify-content: space-between; align-items: center">
          <v-card-title>
            Resources
          </v-card-title>
          <v-btn
            class="mx-1"
            icon
            @click="isSchoolResourcesDialogVisible = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <div class="text-h5 mb-1">
            Resources for {{ school.name }}
          </div>
          <div>
            <school-form
              :school="school"
              class="mb-2"
            />
            <school-links-form
              :school-info="school"
              :school-id="walk.schoolId"
              class="mb-2"
            />
            <school-files
              :school-id="walk.schoolId"
              class="mb-2"
            />
          </div>

          <div class="text-h5 my-1">
            Resource Links
          </div>
          <resource-link-cards />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isDeleteWalkDialogVisible"
      persistent
      max-width="528px"
    >
      <v-card>
        <v-card-title>
          Are you sure?
        </v-card-title>
        <v-card-subtitle>
          Are you sure you want to delete this SchoolDog Walk and all entries associated with it? This cannot be undone.
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="!isSubmitting"
            color="grey darken-1"
            text
            @click="isDeleteWalkDialogVisible = false"
          >
            No, Do Not Delete
          </v-btn>
          <v-btn
            :disabled="isSubmitting"
            :loading="isSubmitting"
            color="primary"
            @click="deleteWalk()"
          >
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isContinueDialogVisible"
      max-width="528px"
    >
      <v-card>
        <v-card-title class="flex-nowrap align-start">
          <span v-if="!walkObservations.length">This SchoolDog Walk has no data!</span>
          <span v-else-if="walkObservations.length === 1">You only logged one entry!</span>
          <span v-else>Done logging entries?</span>
          <v-spacer />
          <v-btn
            icon
            color="grey darken-1"
            @click="isContinueDialogVisible = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="!walkObservations.length">
            Please either keep the SchoolDog Walk open and add a few entries before completing it or delete it entirely.
          </div>
          <div v-else-if="walkObservations.length === 1">
            If you plan to add more entries with SchoolDog at this site in the near future, you can keep the SchoolDog Walk open and log more entries before completing it in order to save time.
          </div>
          <div v-else>
            You have logged <strong>{{ numberOfObservations }} entr{{ numberOfObservations === 1 ? 'y' : 'ies' }}</strong> so far. Are you ready to review and complete, or do you have more entries to log? You can log any number of entries during a SchoolDog Walk.
          </div>
          <v-checkbox
            v-if="walkObservations.length > 1"
            v-model="shouldNotShowContinueDialog"
            hide-details
            label="Don't show this message next time"
          />
          <div
            v-if="!walkObservations.length"
            class="text-center mt-2"
          >
            <v-btn
              v-if="!isOffline"
              color="error"
              text
              @click="isDeleteWalkDialogVisible = true"
            >
              Delete SchoolDog Walk
            </v-btn>
            <div
              v-else
              class="error--text text--darken-2"
            >
              You can delete this SchoolDog Walk once you are back online.
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <template v-if="!walkObservations.length">
            <v-btn
              color="primary"
              @click="finishContinuing(true)"
            >
              Review and complete anyway
            </v-btn>
            <v-btn
              color="primary"
              @click="finishContinuing(false)"
            >
              Keep open
            </v-btn>
          </template>
          <template v-else-if="walkObservations.length === 1">
            <v-btn
              color="primary"
              @click="finishContinuing(true)"
            >
              Review and complete
            </v-btn>
            <v-btn
              color="primary"
              @click="finishContinuing(false)"
            >
              Keep open
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              color="grey darken-1"
              text
              @click="finishContinuing(false)"
            >
              No, log more entries
            </v-btn>
            <v-btn
              color="primary"
              @click="finishContinuing(true)"
            >
              Yes, continue
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import Walk from '@/models/Walk'
import SchoolLocations from '@/models/SchoolLocations'
import EntryCard from '../../components/walks/EntryCard.vue';
import WalkHistoryCard from '../../components/walks/WalkHistoryCard.vue';
import ResourceLinkCards from '../../components/resources/ResourceLinkCards.vue';
import SchoolForm from '../school/SchoolForm.vue';
import SchoolLinksForm from '../school/SchoolLinksForm.vue';
import SchoolFiles from '../school/SchoolFiles.vue';
import WalkHeader from '../../components/walks/WalkHeader.vue';
import AnswerRubric from '../../components/rubric/AnswerRubric.vue';
import LogEntryDialog from '../../components/walks/LogEntryDialog.vue';
import UserLink from '../../components/common/UserLink.vue';
import ManageWalkAccessDialog from '../../components/walks/ManageWalkAccessDialog.vue';
import WalkProgressBar from '../../components/walks/WalkProgressBar.vue';
import GlossaryTerm from '../../components/common/GlossaryTerm.vue';
import { Timestamp } from 'firebase/firestore';

export default {
  name: 'EditWalk',
  components: {
    AnswerRubric,
    EntryCard,
    WalkHistoryCard,
    SchoolFiles,
    SchoolForm,
    SchoolLinksForm,
    WalkHeader,
    ResourceLinkCards,
    ManageWalkAccessDialog,
    LogEntryDialog,
    UserLink,
    WalkProgressBar,
    GlossaryTerm,
  },
  data() {
    return {
      currentTab: 0,
      editingObservation: null,
      editingObservationId: null,
      isContinueDialogVisible: false,
      isLogEntryDialogVisible: false,
      isDeleteWalkDialogVisible: false,
      isEditObservationDialogVisible: false,
      isEditObservationFormValid: false,
      isObservationsDialogVisible: false,
      isObservationsDrawerVisible: true,
      isLoadingWalk: false,
      isSettingsDialogVisible: false,
      isManageWalkAccessDialogVisible: false,
      isReviewingSubmissionDialogVisible: false,
      isSchoolResourcesDialogVisible: false,
      isSentForReviewDialogVisible: false,
      isSubmissionReviewFormValid: false,
      isSubmittingObservation: false,
      isSubmitting: false,
      isUsingTileView: true,
      newObservationCategoryId: null,
      objectRule: value => (Boolean(value) && typeof value === 'object' && Boolean(value.value)) || 'Please choose from provided options',
      requiredRule: value => Boolean(value) || 'This field is required',
      schoolLocations: null,
      submissionReviewSendToSafetyTeam: true,
      submissionReviewerId: null,
      walk: null,
      newTag: null,
      editTag: null,
      acknowledgedZeroEntries: false,
      shouldNotShowContinueDialog: false,
    };
  },
  computed: {
    ...mapGetters('app', [
      'schools',
      'getSchoolById',
    ]),
    ...mapState('app', [
      'isOffline',
      'observationSettings',
      'organizationSettings',
      'user',
      'users',
      'walksById',
    ]),
    isUserLeader () {
      return this.user && this.user.id === this.walk.leaderUserId
    },
    numberOfConcerns () {
      return this.walkObservations.filter(observation => 'concern' === observation.observationType).length
    },
    numberOfOther () {
      return this.walkObservations.filter(observation => 'other' === observation.observationType).length
    },
    numberOfPraises () {
      return this.walkObservations.filter(observation => 'praise' === observation.observationType).length
    },
    numberOfObservations () {
      return this.walkObservations.length
    },
    observationCategoryById() {
      const map = {};
      this.observationCategories.forEach((observationCategory) => {
        map[observationCategory.id] = observationCategory;
      });
      return map;
    },
    observationCategories() {
      if (this.observationSettings) {
        return this.observationSettings.categories;
      }
      return [];
    },
    observationOptionsByCategoryId () {
      const map = {}
      this.observationCategories.forEach((category) => {
        map[category.id] = this.observationItemsByCategory[category.id].map((observationItem) => ({ text: observationItem.label, value: observationItem.id })).sort((a, b) => {
          if (a.text === 'Other' && b.text === 'Other') return 0;
          if (a.text === 'Other') return 1;
          if (b.text === 'Other') return -1;
          return a.text.localeCompare(b.text);
        })
      })
      return map
    },
    observationOptionsWithCategories () {
      const options = []
      this.observationItems.forEach((observationItem) => {
        observationItem.categories.forEach((categoryId) => {
          options.push({
            text: `${observationItem.label} (${this.observationCategoryById[categoryId].label})`,
            value: `${categoryId}__${observationItem.id}`,
          })
        })
      })
      return options.sort((a, b) => {
        if (a.text === 'Other' && b.text === 'Other') return 0;
        if (a.text === 'Other') return 1;
        if (b.text === 'Other') return -1;
        return a.text.localeCompare(b.text);
      });
    },
    observationItems() {
      if (this.observationSettings) {
        return this.observationSettings.items;
      }
      return [];
    },
    observationItemById() {
      const map = {};
      this.observationItems.forEach((observationItem) => {
        map[observationItem.id] = observationItem;
      });
      return map;
    },
    observationItemsByCategory() {
      const map = {};
      if (!this.observationSettings) {
        return map
      }
      this.observationSettings.items.forEach((observationItem) => {
        observationItem.categories.forEach((categoryId) => {
          if (!map[categoryId]) {
            map[categoryId] = []
          }
          map[categoryId].push(observationItem)
        })
      })
      return map;
    },
    reviewedBy() {
      const matchingUsers = this.users.filter((user) => user.id == this.walk.awaitingReviewBy)
      if (matchingUsers.length > 0) {
        return matchingUsers[0];
      }
      return null;
    },
    isLoading() {
      return (
        this.isLoadingWalk
      );
    },
    school() {
      return this.getSchoolById(this.walk.schoolId) || {}
    },
    walkObservations() {
      const observations = Object.values(this.walk.observationsById || {}).filter(observation => !observation.isDeleted)
      observations.sort((a, b) => {
        return new Date(a.timestamp) - new Date(b.timestamp)
      })
      return observations
    },
  },
  methods: {
    ...mapActions('app', [
      'syncWalks',
      'showError',
      'showSuccess',
    ]),
    ...mapMutations('app', [
      'removeWalk',
    ]),
    async completeWalk() {
      this.isSubmitting = true;
      try {
        await this.runFunction('completeWalk', {
          walkId: this.walk.id,
        })
      } finally {
        this.isSubmitting = false
      }
    },
    submitForReviewClicked() {
      this.isReviewingSubmissionDialogVisible = this.validateRubrics();
    },
    validateRubrics() {
      if (this.walk.rubrics?.length > 0) {
        for (let i = 0; i < this.walk.rubrics.length; i++) {
          const rubric = this.walk.rubrics[i];
          if (rubric.skip) {
            continue
          }
          if (!rubric.sections) {
            this.showError({
              message: `Please reconnect to the internet to load and submit the required rubric${this.walk.rubrics.length > 1 ? 's' : ''}.`,
            });
            return false;
          }
          for (let k = 0; k < rubric.sections.length; k++) {
            const section = rubric.sections[k];
            for (let j = 0; j < section.questions.length; j++) {
              // if rubric is visible it should be required to complete the full thing
              if (section.questions[j]?.score === null || section.questions[j]?.score === undefined) {
                this.showError({
                  message: `Please complete the required rubric${this.walk.rubrics.length > 1 ? 's' : ''}.`,
                });
                return false;
              }
            }
          }
        }
      }
      return true;
    },
    async deleteObservation(observation) {
      observation.isDeleted = true
      this.walk.save()
    },
    async doSubmissionAction() {
      this.$refs.submissionReviewForm.validate();
      if (!this.isSubmissionReviewFormValid) {
        return;
      }
      await this.completeWalk();
    },
    editObservation(observationId) {
      this.editingObservationId = observationId
      this.editingObservation = {
        ...this.walk.observationsById[observationId],
      }
      this.isEditObservationDialogVisible = true
    },
    getImagePathForCategory(category) {
      return category.image;
    },
    async deleteWalk() {
      try {
        this.isSubmitting = true;
        await this.runFunction('archiveWalk', {
          walkId: this.walk.id,
        })
        this.showSuccess('Successfully deleted SchoolDog Walk');
        this.removeWalk(this.walk)
        this.$router.replace('/walks');
      } catch {
        this.showError('Failed to delete SchoolDog Walk');
      } finally {
        this.isSubmitting = false;
      }
    },
    async loadWalk() {
      this.isLoadingWalk = true;

      await this.syncWalks()
      try {
        this.walk = await Walk.getById(this.$route.params.walkId)
        if (!this.walk) {
          throw new Error('Walk does not exist');
        }
      } catch (e) {
        console.error(this.$route.params.walkId, e)
        this.$router.replace('/walks');
        return
      }

      if (!this.walk || 'inprogress' !== this.walk.status) {
        this.$router.replace(`/walks/walk/${this.$route.params.walkId}/${this.walk.status}`);
      }

      if (this.walk.submissionHistory && this.walk.submissionHistory) {
        const mostRecentAction = this.walk.submissionHistory[this.walk.submissionHistory.length - 1];
        if (mostRecentAction.fromStatus === 'review' && this.walk.awaitingReviewBy) {
          this.isSentForReviewDialogVisible = true
        }
      }

      this.isLoadingWalk = false;

      this.schoolLocations = await SchoolLocations.getById(this.walk.schoolId)
    },
    openLogEntryDialogForCategory(observationCategoryId) {
      this.newObservationCategoryId = observationCategoryId;
      this.isLogEntryDialogVisible = true;
    },
    async saveEditObservationChanges(observation) {
      this.linkObservationToQuestions(this.editingObservation);
      Object.keys(observation).forEach((key) => {
        this.walk.observationsById[this.editingObservationId][key] = observation[key]
      })
      this.saveWalk('saveedit');
    },
    async saveNewObservation(newObservation) {
      const observation = {
        ...newObservation,
        timestamp: Timestamp.now(),
        reportedByUserId: this.user.id,
        id: this.getFirestoreId(),
      };
      this.linkObservationToQuestions(observation);

      if (!this.walk.observationsById) {
        this.walk.observationsById = {};
      }
      this.walk.observationsById[observation.id] = observation;
      this.walk.save();

      // Don't bother caching common locations while offline, inefficient use of resources
      if (!this.isOffline && observation.location) {
        if (!this.schoolLocations.usageMap[observation.location]) {
          this.$set(this.schoolLocations.usageMap, observation.location, 0);
        }
        this.$set(this.schoolLocations.usageMap, observation.location, this.schoolLocations.usageMap[observation.location] + 1);
        this.schoolLocations.save();
      }
    },
    getFirestoreId() {
      // get random 20 character string with upper and lowercase numbers and letters
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let id = '';
      for (let i = 0; i < 20; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    async saveWalk() {
      this.walk.save()
    },
    refreshTags(observation) {
      const newTags = [];
      if (observation.observationCategoryId) {
        const category = this.observationCategoryById[observation.observationCategoryId];
        const categoryTags = category.tags ?? [];
        categoryTags.forEach((tag) => {
          newTags.push(tag);
        })
      }
      if (observation.observationItemId) {
        const item = this.observationItemById[observation.observationItemId];
        const itemTags = item.tags ?? [];
        itemTags.forEach((tag) => {
          newTags.push(tag);
        })
      }
      observation.manualTags.forEach((tag) => {
        newTags.push(tag);
      })
      observation.tags = [
        ...new Set(newTags),
      ];
    },
    updateRubric(rubric, index) {
      this.walk.rubrics[index] = rubric;
      let score = 0;
      for (let i = 0; i < this.walk.rubrics?.length ?? 0; i++) {
        // calculate running average of rubrics to create normalized total score for multiple rubrics
        score = (score / (i + 1)) + (this.walk.rubrics[i].score / (i + 1));
      }
      this.walk.score = score;
      this.saveWalk();
    },
    linkObservationToQuestions(observation) {
      if (!this.walk.rubrics || !this.walk.rubrics.length) {
        return;
      }
      const observationTags = observation.tags ?? [];
      this.walk.rubrics.forEach((rubric) => {
        rubric.sections.forEach((section) => {
          section.questions.forEach((question) => {
            const questionTags = question.tags ?? [];
            for (let i = 0; i < questionTags.length; i++) {
              for (let j = 0; j < observationTags.length; j++) {
                if ((observation.tags[j] || '').toLowerCase() === (questionTags[i] || '').toLowerCase()) {
                  question.linkedObservations = question.linkedObservations ?? [];
                  if (question.linkedObservations.indexOf(observation.id) < 0) {
                    question.linkedObservations.push(observation.id);
                  }
                  return; // don't need to link to a question more than once
                }
              }
            }
          })
        })
      });
    },
    scrollToSubmit() {
      this.$refs.submitBtn.$el.scrollIntoView({ behavior: 'smooth' });
    },
    scrollToContext() {
      this.$refs.context.scrollIntoView({ behavior: 'smooth' });
    },
    startContinuing() {
      if (localStorage.getItem('hideContinueDialog') && this.walkObservations.length > 1) {
        this.currentTab = 1;
        return;
      }
      this.isContinueDialogVisible = true;
    },
    finishContinuing(isContinuing) {
      if (this.shouldNotShowContinueDialog) {
        localStorage.setItem('hideContinueDialog', true);
      }
      this.isContinueDialogVisible = false;
      if (isContinuing) {
        this.currentTab = 1;
      }
    },
  },
  watch: {
    currentTab () {
      if (0 === this.currentTab && this.walk.currentStage !== 'observations') {
        this.walk.currentStage = 'observations';
      } else if (1 === this.currentTab && this.walk.currentStage !== 'review') {
        this.walk.currentStage = 'review';
      } else if (this.currentTab < 0 || this.currentTab > 2) {
        this.currentTab = 0;
        return;
      }
      localStorage.setItem(`walk-${this.$route.params.walkId}-tab`, this.currentTab)
    },
    'walk.status' () {
      if (this.walk && 'complete' === this.walk.status) {
        this.$router.replace(`/walks/walk/${this.walk.id}/complete`);
        this.showSuccess('SchoolDog Walk Completed');
      }
    },
  },
  async mounted() {
    await this.loadWalk();
    if (this.walk) {
      this.walk.startListening()
    }

    if (localStorage.getItem(`walk-${this.$route.params.walkId}-tab`)) {
      this.currentTab = parseInt(localStorage.getItem(`walk-${this.$route.params.walkId}-tab`))
      if (this.currentTab < 0 || this.currentTab > 2) {
        this.currentTab = 0;
      }
    } else {
      localStorage.setItem(`walk-${this.$route.params.walkId}-tab`, this.currentTab)
    }
  },
  destroyed () {
    if (this.walk) {
      this.walk.stopListening()
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-navigation-drawer__content {
  padding: 0;
}

</style>

<style lang="scss">
.tag-input {
  .v-input__append-outer {
    margin-top: 0px !important;
  }
}

.tag {
  margin: 3px;
}

#add-new-tag-tooltip-mount {
  .v-tooltip__content {
    left: 0px !important;
    top: 0px !important;
    position: relative !important;
  }
}
</style>
